<script>
    import { required } from "vuelidate/lib/validators";
    import Customers from '@/services/Customers';
    import Swal from "sweetalert2";
    import validationMessages from '@/components/validations'

    export default {
        components:{
            validationMessages
        },
        props: {
            emailData: {
                type: Object,
                default: null
            },
        },
        watch: {
            emailData: function(newVal) {
                this.id_e = newVal.email_id;
                this.email_e = newVal.email;
                this.primary_e = newVal.primary;
                this.checked_primary()
            }
        },
        data() {
            return {
                id_e: '',
                email_e: '',
                primary_e: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                primary_checked: false,
            };
        },
        validations: {
            email_e: {
                required
            },
        },
        created() {

        },
        methods: {

            async editCustomerEmail(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {

                        await Customers.updateEmail(this.id_e,{
                            email: this.email_e,
                            primary: this.primary_checked == true ? 'Y' : 'N'
                        }).then((response) => {
                            this.successmsg(response);
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            checked_primary(){
                this.primary_checked = this.primary_e == 'Y' ? true : false;
            },
            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },
        }
    };
</script>

<template>
    <b-modal v-model="showModal" id="mail_edit" title="Edit Customer Email" title-class="font-18" centered>
        <form @submit.prevent="editCustomerEmail">

            <b-form-group label="Email" label-for="formrow-email-input" >
                <b-form-input
                        v-model.trim="email_e"
                        type="text"
                        id="email"
                        aria-describedby="email-feedback"
                        :class="{
                      'is-invalid': submitted && $v.email_e.$error,
                    }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Email'" :validationName="$v.email_e"></validationMessages>
            </b-form-group>

            <b-form-checkbox-group>.
                <div class="form-check form-switch form-switch-lg mb-3" >
                    <input v-model="primary_checked"
                            type="checkbox"
                            class="form-check-input"
                            id="primaryEmail"
                    />
                    <label class="form-check-label" for="primaryEmail">Primary Email</label>
                </div>
            </b-form-checkbox-group>
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editCustomerEmail" :disabled="tryingToEdit">Change</b-button>
        </template>
    </b-modal>
</template>