<script>
    import Orders from '@/services/Orders';
    import modalViewOrder from  '@/components/modals/orders/modalViewOrder';

    export default {
        components: { modalViewOrder },
        props: {
            customer_id: String
        },
        data() {
            return {
                tableOrders: [],
                isBusy: false,
                totalRows: 1,
                currentPage: 1,
                perPage: 2,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "order_id",
                sortDesc: false,
                guid: '',
                fields: [
                    {
                        key: "id",
                        label: "Order ID",
                        sortable: true,
                    },
                    {
                        key: "total",
                        label: "Total",
                        sortable: true,
                    },
                    {
                        key: "paid",
                        label: "Paid",
                        sortable: true,
                    },
                    {
                        key: "currency",
                        label: "Currency",
                        sortable: true,
                    },
                    {
                        key: "source",
                        label: "Source",
                        sortable: false,
                    },
                    {
                        key: "timestamp",
                        label: "Date/Time",
                        sortable: true,
                    },
                    {
                        key: "status",
                        label: "Order Status",
                        sortable: true,
                    },
                    "action",
                ]
            }
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.tableOrders.length;
            }
        },
        mounted() {
            this.getUserOrders()
            this.totalRows = 0;
        },
        methods: {
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            async getUserOrders(){
                this.toggleBusy();
                await Orders.getOrders('customer_id='+this.$route.params.id
                    )
                    .then(response => {
                        this.tableOrders = response.data.data
                        this.toggleBusy();
                    })
                    .catch(error => {
                        this.toggleBusy();
                        this.error = error.response.data.error ? error.response.data.error : "";
                    })
            },

            modalViewOrder(data){
                this.$bvModal.show("view_order");
                this.guid = data.guid;
            },
        }
    }
</script>

<template>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive mb-0">
                <b-table
                    :items="tableOrders"
                    :busy="isBusy"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    empty-text="No Data Found"
                >
                    <template v-slot:cell(status)="data">
                        <div
                                class="badge bg-pill bg-soft-success font-size-12 text-capitalize"
                                :class="{
                                      'bg-soft-danger': data.item.status === 'cancelled',
                                      'bg-soft-warning': data.item.status === 'processing',
                                      'bg-soft-danger': data.item.status === 'failed',
                                    }"
                        >
                            {{ data.item.status }}
                        </div>
                    </template>

                    <template v-slot:cell(action)="data">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <a
                                        href="javascript:void(0);"
                                        @click="modalViewOrder(data.item)"
                                        class="px-2 text-primary"
                                        v-b-tooltip.hover
                                        title="View Order"
                                >
                                    <i class="uil uil-eye font-size-18"></i>
                                </a>
                            </li>
                        </ul>
                    </template>

                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="col-12">
            <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                    ></b-pagination>
                </ul>
            </div>
        </div>

        <!-- MODALS -->
        <modalViewOrder :guid="this.guid"></modalViewOrder>
        <!-- /MODALS -->
    </div>
</template>
