<script>
    import { required, minLength } from "vuelidate/lib/validators";
    import Customers from '@/services/Customers';
    import Swal from "sweetalert2";
    import validationMessages from '@/components/validations'

    export default {
        components:{
            validationMessages
        },
        props: {
            customer_id: {
                type: String
            },
        },
        data() {
            return {
                line1: '',
                line2: '',
                city: '',
                zip: '',
                state_province: '',
                country: '',
                address_type: 'unknown',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                primary_checked: false,
                verified_checked: false,
                mailing_checked: false,
            };
        },
        validations: {
            line1: {
                required,
                minLength: minLength(2),
            },
            line2: {
                required,
            },
            city: {
                required,
                minLength: minLength(2),
            },
            zip: {
                required
            },
            country: {
                required
            },
        },
        created() {
        },
        methods: {

            async addCustomerAddress(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        await Customers.addAddress({
                            customer_id: this.customer_id,
                            line1: this.line1,
                            line2: this.line2,
                            city: this.city,
                            state_province: this.state_province,
                            zip: this.zip,
                            country: this.country,
                            address_type: this.address_type,
                            primary: this.primary_checked == true ? 'Y' : 'N',
                            verified: this.verified_checked == true ? 'Y' : 'N',
                            mailing: this.mailing_checked == true ? 'Y' : 'N'
                        }).then((response) => {
                            this.successmsg(response);
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            checked_primary(){
                this.primary_checked = this.primary == 'Y' ? true : false;
            },
            checked_verified(){
                this.verified_checked = this.verified == 'Y' ? true : false;
            },
            checked_mailing(){
                this.mailing_checked = this.mailing == 'Y' ? true : false;
            },
            refreshData() {
                this.$emit('onRefresh') //event from parent
            },
            closeModal() {
                this.showModal = false;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },
        }
    };
</script>

<template>
    <b-modal v-model="showModal" id="address_add" title="Add Customer Address" title-class="font-18" centered>
        <form @submit.prevent="addCustomerAddress">

            <b-form-group label="Street Name" label-for="formrow-streetname-input" class="mb-3">
                <b-form-input
                        v-model.trim="line1"
                        type="text"
                        id="line1"
                        aria-describedby="line1-feedback"
                        :class="{
                      'is-invalid': submitted && $v.line1.$error,
                    }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Street Name'" :validationName="$v.line1"></validationMessages>
            </b-form-group>

            <b-form-group label="House Number" label-for="formrow-housenumber-input" class="mb-3">
                <b-form-input
                        v-model.trim="line2"
                        type="text"
                        id="line2"
                        aria-describedby="line2-feedback"
                        :class="{
                      'is-invalid': submitted && $v.line2.$error,
                    }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'House Number'" :validationName="$v.line2"></validationMessages>
            </b-form-group>

            <b-form-group label="City" label-for="formrow-city-input" class="mb-3">
                <b-form-input
                        v-model.trim="city"
                        type="text"
                        id="city"
                        aria-describedby="city-feedback"
                        :class="{
                      'is-invalid': submitted && $v.city.$error,
                    }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'City'" :validationName="$v.city"></validationMessages>
            </b-form-group>

            <b-form-group label="State Province" label-for="formrow-stateprovince-input" class="mb-3">
                <b-form-input
                        v-model.trim="state_province"
                        type="text"
                        id="state_province"
                        aria-describedby="stateprovince-feedback"
                >
                </b-form-input>
            </b-form-group>

            <b-form-group label="Zip" label-for="formrow-zip-input" class="mb-3">
                <b-form-input
                        v-model.trim="zip"
                        type="text"
                        id="zip"
                        aria-describedby="zip-feedback"
                        :class="{
                      'is-invalid': submitted && $v.zip.$error,
                    }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Zip'" :validationName="$v.zip"></validationMessages>
            </b-form-group>

            <b-form-group label="Country" label-for="formrow-country-input" class="mb-3">
                <b-form-input
                        v-model.trim="country"
                        type="text"
                        id="country"
                        aria-describedby="country-feedback"
                        :class="{
                      'is-invalid': submitted && $v.country.$error,
                    }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Country'" :validationName="$v.country"></validationMessages>
            </b-form-group>

            <b-form-group label="Address Type" label-for="formrow-address_type-input" class="mb-3">
                <select v-model="address_type" class="form-select">
                    <option value="unknown">Unknown</option>
                    <option value="home">Home</option>
                    <option value="business">Business</option>
                </select>
            </b-form-group>

            <b-form-checkbox-group>
                <div class="form-check form-switch form-switch-lg mb-3" >
                    <input v-model="primary_checked"
                            type="checkbox"
                            class="form-check-input"
                            id="primaryAddress"
                    />
                    <label class="form-check-label" for="primaryAddress">Primary Address</label>
                </div>
            </b-form-checkbox-group>

            <b-form-checkbox-group>
                <div class="form-check form-switch form-switch-lg mb-3" >
                    <input v-model="verified_checked"
                           type="checkbox"
                           class="form-check-input"
                           id="verifiedAddress"
                    />
                    <label class="form-check-label" for="verifiedAddress">Verified Address</label>
                </div>
            </b-form-checkbox-group>

            <b-form-checkbox-group>
                <div class="form-check form-switch form-switch-lg mb-3" >
                    <input v-model="mailing_checked"
                           type="checkbox"
                           class="form-check-input"
                           id="mailingAddress"
                    />
                    <label class="form-check-label" for="mailingAddress">Mailing Address</label>
                </div>
            </b-form-checkbox-group>

        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addCustomerAddress" :disabled="tryingToEdit">Submit</b-button>
        </template>
    </b-modal>
</template>